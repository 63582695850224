<form name="form" #formLeftParadeRing="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">Parade Ring</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="meta.loading">
      <div class="row">
        <div class="col-12">
          <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
        </div>
      </div>
    </div>
    <div  *ngIf="!meta.loading">
      <div class="row" *ngIf="paradeRingRaces.leftParadeRingTime">
        <div class="col">
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Advertised Time of Race</label>
            <p class="d-inline float-right">{{paradeRingRaces.publishedRaceTime | racetime}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Left Parade Ring</label>
            <p class="d-inline float-right">{{paradeRingRaces.leftParadeRingTime | time}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Further comments</label>
            <p class="d-inline float-right">{{paradeRingRaces.action}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!paradeRingRaces.leftParadeRingTime">
        <div class="col">
          <div class="form-group">
            <label class="font-weight-bold">Last horse left Parade Ring (HH:MM:SS): *</label>
            <ngb-timepicker name="leftParadeRingTime" [(ngModel)]="time" (ngModelChange)="onTimeChange()" [seconds]="true" [required]="true" [meridian]="true"></ngb-timepicker>         
         </div>
          <!-- ACTIONS -->
          <div class="form-group">
            <label class="font-weight-bold">Further comments:</label>
            <textarea class="form-control" name="action" [(ngModel)]="action"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!meta.loading">
    <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
      <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
      <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="formLeftParadeRing.invalid" [hidden]="paradeRingRaces.leftParadeRingTime">Confirm</button>
      <!--    <button type="button" class="btn btn-primary" (click)="editLateRace()">edit</button>-->
      <button type="button" class="btn btn-danger" (click)="deleteRace()" *ngIf="paradeRingRaces.leftParadeRingTime">Delete</button>
    </div>
    <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
      <h1>
        <fa name="circle-o-notch" animation="spin"></fa>
      </h1>
    </div>
  </div>
</form>
